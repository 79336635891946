<template>
  <el-dialog
    class="chat-user"
    title="转发消息"
    :visible.sync="visible"
    width="600px"
    :before-close="onClose"
  >
    <el-collapse v-model="activeNames" @change="handleChange">
      <el-collapse-item title="好友" name="1">
        <el-scrollbar class="friend-list-items">
          <div
            v-for="(friend, index) in $store.state.friendStore.friends"
            :key="index"
          >
            <div
              class="friend-item"
              @click="onSuccess(1,friend.id,friend)"
              :class="
                friend === $store.state.friendStore.activeFriend ? 'active' : ''
              "
            >
              <div class="friend-avatar">
                <head-image
                  :size="45"
                  :name="friend.nickName"
                  :url="friend.headImage"
                  :online="friend.online"
                >
                </head-image>
              </div>
              <div class="friend-info">
                <div class="friend-name">{{ friend.nickName }}</div>
                <div class="friend-online">
                  <el-image
                    v-show="friend.onlineWeb"
                    class="online"
                    :src="require('@/assets/image/online_web.png')"
                    title="电脑设备在线"
                  />
                  <el-image
                    v-show="friend.onlineApp"
                    class="online"
                    :src="require('@/assets/image/online_app.png')"
                    title="移动设备在线"
                  />
                </div>
              </div>
              <slot></slot>
            </div>
          </div>
        </el-scrollbar>
      </el-collapse-item>
      <el-collapse-item title="群聊" name="2">
        <el-scrollbar class="group-list-items">
          <div v-for="(group, index) in groupStore.groups" :key="index">
            <div
              class="group-item"
              @click="onSuccess(2,group.id,group)"
              :class="group === groupStore.activeGroup ? 'active' : ''"
            >
              <div class="group-avatar">
                <head-image
                  :size="45"
                  :name="group.remark"
                  :url="group.headImage"
                >
                </head-image>
              </div>
              <div class="group-name">
                <div>{{ group.remark }}</div>
              </div>
            </div>
          </div>
        </el-scrollbar>
      </el-collapse-item>
    </el-collapse>
  </el-dialog>
</template>

<script>
import HeadImage from "../common/HeadImage.vue";
export default {
  name: "chatUser",
  components: {
    HeadImage,
  },
  computed: {
    friendStore() {
      return this.$store.state.friendStore;
    },
    groupStore() {
      return this.$store.state.groupStore;
    },
  },
  props: {
    visible: {
      type: Boolean,
    },
    msgInfo: {
      type: Object,
    },
  },
  data() {
    return {
      activeNames: ["1"],
    };
  },
  methods: {
    onClose() {
      this.$emit("close");
    },
    handleChange(val) {
      console.log(val);
    },
    onSuccess(type,id,data) {
      //type 1 好友 2 群聊

      this.msgInfo.chatType = type;
	    this.msgInfo.chatId = id
      this.msgInfo.userInfo = data
      this.$emit("send", this.msgInfo);
    },
  },
};
</script>

<style lang="scss">
.group-item {
  height: 50px;
  display: flex;
  margin-bottom: 1px;
  position: relative;
  padding: 5px;
  padding-left: 10px;
  align-items: center;
  background-color: #fafafa;
  white-space: nowrap;
  &:hover {
    background-color: #eeeeee;
  }

  &.active {
    background-color: #dddddd;
  }

  .group-avatar {
    width: 45px;
    height: 45px;
  }

  .group-name {
    padding-left: 10px;
    height: 100%;
    text-align: left;
    line-height: 50px;
    white-space: nowrap;
    overflow: hidden;
    font-size: 14px;
    font-weight: 600;
  }
}
</style>
